import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import CallToAction from "../../CallToAction/CallToAction";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "./VsfBanner.scss";

const VsfBanner = (props) => {
  return (
    <div className="vsf-banner">
      {props.secondLinkUrl && props.secondLinkUrl && (
        <Row className="vsf-banner-button">
          <Col>
            <Link
              to={props.secondLinkUrl}
              className="btn-green vsf-banner-button-link d-flex align-items-center justify-content-center"
            >
              {props.secondLinkLabel}
            </Link>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-lg-8 col-12 vsf-banner-title">{props.title}</Col>
      </Row>
      <Row className="align-items-lg-center">
        <Col className="col-lg-8 col-12 vsf-banner-description">
          {props.description && <p>{props.description}</p>}
        </Col>
      </Row>
      {props.vsfImage && (
        <Row>
          <Col className="col-lg-10 col-12 vsf-banner-image">
            <GatsbyImage
              className="big-image"
              image={getImage(props.vsfImage)}
              alt={props.vsfImage.title}
            />
          </Col>
        </Row>
      )}
      {props.firstLinkUrl && props.firstLinkUrl && (
        <Row>
          <Col className="col-12 vsf-banner-link">
            <CallToAction
              to={props.firstLinkUrl}
              label={props.firstLinkLabel}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

VsfBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  firstLinkUrl: PropTypes.string,
  firstLinkLabel: PropTypes.string,
  secondLinkUrl: PropTypes.string,
  secondLinkLabel: PropTypes.string,
  vsfImage: PropTypes.object,
};

export default VsfBanner;

import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Campus.scss";
import query from "./query";
import PropTypes from "prop-types";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import VsfBanner from "./VsfBanner/VsfBanner";
import CustomSwiper from "../CustomSwiper/CustomSwiper";

const Campus = (props) => {
  const data = query();
  const campusPage = filterDataByLanguage(
    props.lang,
    data.allContentfulCampusPage
  );
  const campusItems = filterListDataByLanguage(
    props.lang,
    data.allContentfulCampusItem
  );
  const vsfBanner = filterListDataByLanguage(
    props.lang,
    data.allContentfulVsfBanner
  );
  const imagedata = campusPage.sliderImages;
  const vsfBannerData =
    Array.isArray(vsfBanner) && vsfBanner[0] ? vsfBanner[0] : vsfBanner;

  return (
    <div>
      <div className="campus-container">
        <Container>
          <div className="row-container">
            <Row className="pb-lg-5 pb-0 campus-row campus-row-top align-items-center">
              <Col className="col-xl-6 col-lg-6 col-12 campus-title">
                {campusPage.titleImage && (
                  <GatsbyImage
                    image={getImage(campusPage.titleImage)}
                    alt={campusPage.titleImage.title}
                  />
                )}
                {!campusPage.titleImage && <div>{campusPage.title}</div>}
              </Col>
              <Col className="col-xl-6 col-lg-6 col-12 campus-description">
                {campusPage.description.description}
              </Col>
            </Row>
          </div>
          <div className="row-container">
            {campusPage.numbers.map((row, index) => {
              const isLast =
                campusPage.numbers.length - 1 === index ? "last-number" : "";
              return (
                <Row
                  key={`${index}-${row.id}`}
                  className={
                    "pt-lg-4 our-numbers-row d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-start flex-column " +
                    isLast
                  }
                >
                  <Col className="col-lg-6 our-numbers-info-digit pt-lg-0 mt-lg-0 pt-3 pb-3 text-lg-center">
                    {row.key}
                  </Col>
                  <Col className="col-lg-3 our-numbers-info-title pb-3 text-lg-center">
                    {row.value}
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className={`${!campusPage.bigImage ? "pb-5" : ""}`}>
            <CustomSwiper slides={imagedata} />
          </div>
          {campusPage.bigImage && (
            <Row className={"pb-0 campus-row row-with-big-image"}>
              <GatsbyImage
                className="big-image"
                image={getImage(campusPage.bigImage)}
                alt={campusPage.bigImage.title}
              />
            </Row>
          )}
          {campusItems.map((row, index) => {
            const isOdd = (index + 1) % 2 === 1 ? " campus-row-odd" : "";
            return (
              <div
                className={`${isOdd}${
                  !campusPage.bigImage && isOdd ? " pt-5" : ""
                }`}
                key={`${index}-${row.id}`}
              >
                <Row className={"pb-lg-5 pb-0 campus-row"}>
                  <Col className="col-xl-6 col-lg-6 col-12 pt-4 campus-title">
                    {row.title}
                  </Col>
                  <Col className="col-xl-6 col-lg-6 col-12 campus-description">
                    {row.entries.map((value) => {
                      return (
                        <>
                          <div className="row mt-3">
                            <div className="px-arrow-right-icon col-1"></div>
                            <span className="px-arrow-right-text col-11">
                              {" " + value.content}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            );
          })}
          <div className="row-container">
            <VsfBanner
              title={vsfBannerData.title}
              description={vsfBannerData.description?.description}
              firstLinkUrl={vsfBannerData.firstLinkUrl}
              firstLinkLabel={vsfBannerData.firstLinkLabel}
              secondLinkUrl={vsfBannerData.secondLinkUrl}
              secondLinkLabel={vsfBannerData.secondLinkLabel}
              vsfImage={vsfBannerData.vsfImage}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

Campus.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Campus;

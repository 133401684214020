import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Campus from "../../Components/Campus/Campus";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const CampusPage = () => {
  return (
    <>
      <Seo locale="it" page="campus" />
      <Header isDarkTheme={false} lang="it" />
      <HamburgerMenu />
      <Campus lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default CampusPage;

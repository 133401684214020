import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulCampusPage {
        nodes {
          node_locale
          title
          description {
            description
          }
          sliderImages {
            gatsbyImageData
            title
          }
          numbers {
            key
            value
          }
          titleImage {
            gatsbyImageData
            title
          }
        }
      }
      allContentfulCampusItem {
        nodes {
          node_locale
          title
          entries {
            content
          }
        }
      }
      allContentfulVsfBanner {
        nodes {
          node_locale
          title
          secondLinkUrl
          secondLinkLabel
          firstLinkUrl
          firstLinkLabel
          description {
            description
          }
          vsfImage {
            gatsbyImageData
            title
          }
        }
      }
    }
  `);
};

export default query;
